function getScrollTarget(id) {
    return document.getElementById(id);
}
Array.from(document.getElementsByTagName('a')).forEach(function (element) {
    var remove = window.location.protocol
        + '//'
        + window.location.host
        + window.location.pathname;
    var href = element.href.replace(remove, '');
    if (href.startsWith('#') === false) {
        return;
    }
    var scrollTarget = getScrollTarget(href.substring(1));
    if (scrollTarget === null) {
        return;
    }
    element.addEventListener('click', function (event) {
        var _a;
        event.preventDefault();
        var navbar = document.getElementById('page-navigation-bar');
        window.scrollTo({
            top: scrollTarget.offsetTop - ((_a = navbar === null || navbar === void 0 ? void 0 : navbar.offsetHeight) !== null && _a !== void 0 ? _a : 0),
            behavior: "smooth"
        });
    });
});
export {};
